<template>
  <div class="components-essentials-closebutton-container" @click="close">
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd)">
        <path
          d="M7 22L22 7"
          stroke="white"
          stroke-width="4"
          stroke-linecap="square"
        />
        <path
          d="M7 7L22 22"
          stroke="white"
          stroke-width="4"
          stroke-linecap="square"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd"
          x="0.67157"
          y="0.67157"
          width="28.6569"
          height="28.6569"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="0.5" dy="0.5" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="0.5" dy="0.5" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
import { mapStores } from 'pinia';

export default {
  props: {
    closePopup: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useMainStore),
    platform() {
      return this.mainStore.platform;
    },
    uri_scheme() {
      return this.mainStore.uri_scheme;
    },
  },
  methods: {
    close() {
      if (this.platform === 'desktop' || this.platform === 'mobileweb') {
        const msg = this.closePopup
          ? 'tee_closePopup'
          : `${this.uri_scheme}tee_close`;
        window.parent.postMessage(msg, this.mainStore.frontend_url);
      } else {
        window.location.href = '#close';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.components-essentials-closebutton-container {
  @apply fixed right-0 top-0 mt-1 mr-5 z-50 cursor-pointer;
}

svg {
  @apply stroke-black;
}
</style>
